@tailwind components;

@layer components {
  .article-editor-container {
    @apply flex flex-col bg-white;
    .editor-container {
      @apply flex-1 overflow-visible;
    }

    .toolbar-wrapper {
      @apply sticky inset-x-0 top-0 z-10 h-11;
    }

    .editor {
      @apply -ml-16 pl-14;
    }

    > .editor-container > .editor,
    > .editor-container > .add-line-container {
      @apply self-center;
      width: calc(698px + 2 * 24px);
      @media (max-width: 1280px) {
        @apply w-full;
        max-width: 640px;
      }

      @media (max-width: 950px) {
        @apply w-full;
        margin: 0 20px;
      }
    }

    .headline {
      @apply my-4 rounded-none border-b border-transparent pb-2 font-bold;
    }

    .editor[contenteditable="true"] .headline {
      &:hover,
      &.has-focus {
        @apply border-b border-primary-border outline-0;
      }
    }

    .editor p.is-empty:first-of-type::before,
    .headline.is-empty::before {
      @apply pointer-events-none float-left h-0 text-secondary-on-light;
      content: attr(data-placeholder);
    }

    .title {
      @apply text-2xl;
    }

    .chapo {
      @apply text-xl;
    }
  }
}
